var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('imageDetails',{attrs:{"imageData":_vm.imageData}})],1),_c('v-card-actions',[_c('BtnWithTooltip',{attrs:{"btnClass":['mx-2'],"btnProps":{ color: 'error', text: true, disabled: _vm.isDefaultImage },"btnText":"DELETE","btnTextSide":"right","iconProps":{
        icon: 'mdi-trash-can',
        color: 'error',
        small: true,
        left: true
      },"tooltipProps":{ color: 'primary', disabled: false, top: true },"tooltipText":_vm.isDefaultImage ? 'Default image cant\'t be deleted' : 'Delete Image'},on:{"click":_vm.imageDeletePrompt}}),_c('v-spacer'),_c('BtnWithTooltip',{attrs:{"btnClass":['mx-2'],"btnProps":{ color: 'warning', text: true },"btnText":"EDIT","btnTextSide":"right","iconProps":{
        icon: 'mdi-square-edit-outline',
        color: 'warning',
        small: true,
        left: true
      },"tooltipProps":{ color: 'primary', disabled: false, top: true },"tooltipText":'Edit Image Properties'},on:{"click":function($event){return _vm.imageEdit(_vm.imageData)}}}),_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":_vm.close}},[_vm._v("CLOSE")])],1),_c('v-dialog',{attrs:{"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.modalEdit),callback:function ($$v) {_vm.modalEdit=$$v},expression:"modalEdit"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{attrs:{"primary-title":""}}),_c('v-card-text',[_c('v-text-field',{staticClass:"my-4",attrs:{"label":"Name","autofocus":""},model:{value:(_vm.imageRename),callback:function ($$v) {_vm.imageRename=$$v},expression:"imageRename"}}),_c('v-select',{attrs:{"disabled":"","label":"Tags"}}),_c('v-switch',{key:_vm.isDefaultImage,staticClass:"ml-4",attrs:{"disabled":_vm.isDefaultImage,"messages":_vm.isDefaultImage ? 'Toggle default on another image to remove.' : '',"label":"Default Image","input-value":_vm.isDefaultImage},on:{"change":_vm.setDefaultImage}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalEdit = false}}},[_vm._v("CANCEL")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success","disabled":_vm.imageEditSaveDisabled,"loading":_vm.imageEditSaveLoading},on:{"click":_vm.saveImageEdit}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("SAVE")],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px","width":"unset","transition":"dialog-transition"},model:{value:(_vm.modalDelete),callback:function ($$v) {_vm.modalDelete=$$v},expression:"modalDelete"}},[_c('imageDelete',_vm._b({on:{"closeDelete":function($event){_vm.modalDelete = false},"actionBtn":_vm.onDeleteConfirm}},'imageDelete',_vm.imageDeleteData,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }